<template>
    <v-container class="corpo" fluid>
        <v-row>
            <v-col style="padding:6%;">
                <v-card style="text-align:center;padding:4%;font-size: larger">
                        <v-form
                        >
                        <v-row style="margin-bottom:5%;">
                        <v-col>
                            <h2 style="color:#0D3282">E-MAIL</h2>
                        </v-col>      
                        </v-row>
                        <v-row>
                        <v-col>
                            <v-text-field
                            v-model="nome"
                            :rules="nameRules"
                            label=" Seu Nome"
                            required
                        ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="E-mail"
                            required
                        ></v-text-field>
                        </v-col> 
                        </v-row>
                        <v-row>
                        <v-col cols="6">
                            <v-text-field
                            v-model="telefone"
                            label="Telefone"
                            v-mask="'(##) #####-####'"
                            required
                        ></v-text-field>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col>
                            <v-text-field
                            v-model="empresa"
                            :rules="empresaRules"
                            label="Nome da Empresa"
                            required
                        ></v-text-field>
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col>
                            <v-text-field
                            v-model="assunto"
                            :rules="assuntoRules"
                            label="Assunto"
                            required
                        ></v-text-field>
                        </v-col>
                        </v-row>
                        <br><br>

                        <v-textarea
                            outlined
                            v-model="menssage"
                            :rules="menssageRules"
                            placeholder="Digite aqui sua mensagem..."
                            auto-grow
                            autocomplete="email"
                        ></v-textarea>
                        
                        <v-checkbox
                        v-model="allowSpaces"
                        label="Informamos que coletamos seus dados pessoais (Nome, Telefone, E-mail) para fins de manter contato com a empresa HC Serafim. Os seus dados pessoais serão armazenados e preservados por um período indeterminado; os dados dos clientes são registrados no sistema e podem ser anonimizados a qualquer momento, desde que o cliente solicite."
                        required
                        ></v-checkbox>
                        
                        <v-btn
                            class="mr-4"
                            style="margin-top:3%;background-color:#0D3282;color:white"
                            value="Send"
                            @click="sendEmail"
                        >
                            Enviar
                        </v-btn>
                        </v-form>
                    </v-card>
            </v-col>
        </v-row>
        <v-container>
            <v-row style="justify-content: center;">  
                        <v-col style="color:#0D3282;font-weight: bold;text-align: center;padding: 3%;">
                            <a class="link" target="_blank" href="https://api.whatsapp.com/send?phone=5511992274765">
                                <v-icon
                                    style="color:#0D3282;margin-right:2%;font-size: 60px;" x-large>
                                    mdi-whatsapp
                                </v-icon> <br> <br>
                                +55 11 99227 4765
                            </a>
                        </v-col>
                        <v-col style="color:#0D3282;font-weight: bold;text-align: center;padding: 3%;">
                            <v-icon
                            style="color:#0D3282;margin-right:2%;font-size: 60px;" x-large>
                            mdi-at
                            </v-icon> <br><br>
                            contato@hcserafim.com.br
                        </v-col>   
                </v-row>
                <v-row fluid style="justify-content: center;">              
                        <v-col style="color:#0D3282;font-weight: bold;text-align: center;padding: 3%;margin-bottom:3%;">
                            <v-icon
                            style="color:#0D3282;margin-right:2%;font-size: 60px;" x-large>
                            mdi-office-building-marker
                            </v-icon> <br> <br>
                            R: Sobral Júnior 167 - São Paulo - SP, <br> CEP 02130-020 - BRASIL
                        </v-col>
            </v-row>
            </v-container>
            
    </v-container>
</template>


<script>
export default {
    name: 'Fale',
    data(){
      return {
      allowSpaces: false,
      nome: '',
      email: '',
      telefone:'',
      empresa:'',
      assunto:'',
      menssage:'',
      emailRules: [
        v => !!v || 'E-mail é obrigatório',
        v => /.+@.+/.test(v) || 'E-mail inválido',
      ],
      nameRules: [
        v => !!v || 'Campo Obrigatório',
        v => v.length <= 50 || 'Campo não pode ser maior do que 50 caracteres',
      ],
      empresaRules: [
        v => !!v || 'Campo Obrigatório',
        v => v.length <= 50 || 'Campo não pode ser maior do que 50 caracteres',
      ],
      assuntoRules: [
        v => !!v || 'Campo Obrigatório',
        v => v.length <= 50 || 'Campo não pode ser maior do que 50 caracteres',
      ],
      menssageRules: [
        v => !!v || 'Campo Obrigatório',
        v => v.length <= 500 || 'Campo não pode ser maior do que 500 caracteres',
      ],
      }
    },
    methods: {
        sendEmail: async function() {
        await emailjs.send('contatoHCserafim','template_HCserafim',{
          from_name : this.nome,
          from_empresa : this.empresa ,
          from_message : this.menssage,
          from_fone : this.telefone ,
          reply_to : this.email ,
          user_mail : this.email  ,
          customer_name : this.nome ,
          },'user_fxpZ066KXTulLG00FXMhe')
        .then((result) => {
            alert('E-mail enviado com sucesso, obrigado!');
            console.log('SUCCESS!', result.status, result.text);
        }, (error) => {
            console.log('FAILED...', error);
        });
      }
    },
}
</script>


<style scoped>
.link{
    text-decoration: none!important;
    color:#0D3282;
}

@media only screen and (max-width: 600px) {
  .corpo{
      margin-bottom: 1.5em;
  }
}
</style>